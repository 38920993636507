import React, { useCallback } from 'react';
import ReactPortableText from 'react-portable-text';

import StatsGrid from './blocks/StatsGrid';

const PortableText = ({ content }) => {
  const statsGridMemo = useCallback(({ stats }) => <StatsGrid stats={stats} />, []);
  return (
    <ReactPortableText
      content={content}
      projectId={process.env.GATSBY_SANITY_PROJECT_ID}
      dataset={process.env.GATSBY_SANITY_DATASET}
      serializers={{
        statsGrid: statsGridMemo
      }}
    />
  );
};

export default PortableText;
